import { http, upload } from './api.js';

export const loadtext = () => { return http.post('/load.php'); };

export const savetext = (content) => { return http.post('/save.php', { content: content }); };

export const getfile = () => { return http.post('/getFiles.php'); };

export const savefile = (formData, onUploadProgress) => { return upload.post('/upload.php', formData, { onUploadProgress }); };

export const deletefile = (name) => { return http.post('/deleteFile.php', { name: name }); };

export const getsonglist = () => { return http.get('/songlist.php'); };

export const getnetlist = () => { return http.post('/getnet.php'); };

export const getweblist = () => { return http.post('/getweb.php'); };

export const getdeveloplist = () => { return http.post('/getdevelop.php'); };

export const getmajorlist = () => { return http.post('/getmajor.php'); };

export const gettoollist = () => { return http.post('/gettool.php'); };

export const getinfolist = () => { return http.post('/getinfo.php'); };





export const dltlist = (id, title) => { return http.post('/dltlist.php', { id: id, title }); };

export const adlist = (file, data, title) => { return upload.post('/addlist.php', { file, data, title }); };

export const upimg = (file, data, title) => { return upload.post('/upimg.php', { file, data, title }); };

export const uplist = (data, title) => { return http.post('/uplist.php', { data, title }); };




export const getlinklist = () => { return http.post('/getlink.php'); };

export const uplink = (data, id) => { return http.post('/uplink.php', { data, id: id }); };

export const dltlink = (id) => { return http.post('/dltlink.php', { id: id }); };

export const adlink = (data) => { return http.post('/addlink.php', { data }); };



export const gettranslate = (data) => { return http.post('/translate.php', { data }); };

export const getlogin = (username, password) => { return http.post('/login.php', { username, password }); };


export const getplaylist = () => { return http.post('/playlist.php'); };