<template>
  <div id="app" :style="{ height: windowHeight + 'px' }" class="contentarea">
    <div class="content-wrapper" ref="wrapper">
      <Header></Header>
      <ContentPage class="content-page"></ContentPage>
    </div>
    <BootMusic class="boot-music" :style="{ bottom: (windowWidth < 768 ? '7vh' : '9vh') }"></BootMusic>
    <SideBar></SideBar>
    <FootBar class="foot-bar" :style="{ height: (windowWidth < 768 ? '7vh' : '9vh') }"></FootBar>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import ContentPage from "./components/ContentPage.vue";
import FootBar from "./components/FootBar.vue";
import SideBar from "./components/SideBar.vue";
import BootMusic from "./components/BootMusic.vue";

export default {
  components: {
    Header,
    ContentPage,
    FootBar,
    SideBar,
    BootMusic
  },
  data() {
    return {
      windowHeight: 0,
      windowWidth: window.innerWidth
    };
  },
  methods: {
    scrollTop() {
      this.$refs.wrapper.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    }
  },
  mounted() {
    this.scrollTop();
    this.$eventBus.$on("scroll-to-top", this.scrollTop);
    this.windowHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    this.$eventBus.$off("scroll-to-top", this.scrollTop);
    window.removeEventListener("resize", this.handleResize);
  }
};
</script>

<style scoped>
/* 默认样式 */
.content-wrapper {
  height: 85vh;
  width: 100%;
  padding-bottom: 13vh;
  overflow-y: auto;
  overflow-y: scroll;
  scrollbar-width: none;
  background: rgb(241, 240, 240);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.boot-music {
  position: fixed;
  bottom: 9vh;
  left: 0;
}

.contentarea {
  position: fixed;
  margin: 0;
  padding: 0;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

.foot-bar {
  position: fixed;
  bottom: 0;
  width: 100%;
}

/* 媒体查询样式 */
@media (max-width: 768px) {
  .content-wrapper {
    height: 79vh;
  }

  .boot-music {
    bottom: 7vh;
  }

  .foot-bar {
    height: 7vh;
  }
}
</style>
