<template>
    <div  >
      <input type="text" style="width: 100%; border: none;" v-model="cellData" @input="handleInput" >
    </div>
  </template>
  
  <script>
  export default {
    props: {
      value: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        cellData: this.value,
      };
    },
    methods: {
      handleInput() {
        this.$emit('input', this.cellData);
      },
    },
  };
  </script>
  