/* eslint-disable */

import Vue from 'vue';
import App from './App.vue';
import Vant from 'vant';
import store from './store';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import './assets/icon/iconfont.css';
import 'element-ui/lib/theme-chalk/index.css';
import '@vant/touch-emulator';

import {
  loadtext, savetext, savefile, deletefile, getfile,

  getnetlist, getweblist, getdeveloplist, getmajorlist, gettoollist, getinfolist,

  dltlist, upimg, adlist, uplist, getlogin, gettranslate, getplaylist,

  getlinklist, uplink, dltlink, adlink, getsonglist


} from './api/main-router';

Vue.mixin({
  methods: {
    loadtext, savetext, savefile, deletefile, getfile,

    getnetlist, getweblist, getdeveloplist, getmajorlist, gettoollist, getinfolist,

    dltlist, upimg, adlist, uplist, getlogin, gettranslate, getplaylist,

    getlinklist, uplink, dltlink, adlink, getsonglist
  }
});
Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();
Vue.use(ElementUI);
Vue.use(Vant);

new Vue({
  store,
  render: h => h(App)
}).$mount('#app');
