import axios from 'axios';

const http = axios.create({
  // baseURL: 'https://yjl67525.top/web-api',
  baseURL: '/web-api',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  transformRequest: [
    function (data) {
      let ret = '';
      for (let it in data) {
        ret +=
          encodeURIComponent(it) +
          '=' +
          encodeURIComponent(data[it]) +
          '&';
      }
      return ret;
    }
  ]
});

const upload = axios.create({
  // baseURL: 'https://yjl67525.top/web-api',
  baseURL: '/web-api',

  headers: {
    'Content-Type': 'multipart/form-data'
  }
});

export { http, upload };