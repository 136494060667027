<template>
  <div class="nav-bar-container" @click="sideshow">
    <div class="nav-bar-item" @click="scrollToCommon" :class="{ active: tablesle === '常用' }">
      <i class="el-icon-suitcase"></i>
      <span>常用</span>
    </div>

    <div class="nav-bar-item" @click="scrollToNetWebsites" :class="{ active: tablesle === 'Ntion' }">
      <i class="el-icon-coin"></i>
      <span>NET</span>
    </div>

    <div class="nav-bar-item" @click="scrollTomajorWebsites" :class="{ active: tablesle === '专业' }">
      <i class="el-icon-help"></i>
      <span>专业</span>
    </div>

    <div class="nav-bar-item" @click="scrolldevelopWebsites" :class="{ active: tablesle === '编程' }">
      <i class="el-icon-monitor"></i>
      <span>编程</span>
    </div>

    <div class="nav-bar-item" @click="scrollTotoolWebsites" :class="{ active: tablesle === '工具' }">
      <i class="el-icon-setting"></i>
      <span>工具</span>
    </div>

    <div class="nav-bar-item" @click="scrollToinfoWebsites" :class="{ active: tablesle === '咨询' }">
      <i class="el-icon-connection"></i>
      <span>咨询</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FootBar",

  data() {
    return {
      tablesle: ""
    };
  },
  methods: {
    sideshow() {
      if (this.$store.state.sideBarshow) {
        this.$eventBus.$emit("show-side-bar");
      }
    },
    scrollToCommon() {
      this.$eventBus.$emit("scroll-to-component", "commonWebsites");
      this.tablesle = "常用";
    },
    scrollToNetWebsites() {
      this.$eventBus.$emit("scroll-to-component", "NetWebsites");
      this.tablesle = "Ntion";
    },
    scrollTomajorWebsites() {
      this.$eventBus.$emit("scroll-to-component", "majorWebsites");
      this.tablesle = "专业";
    },
    scrolldevelopWebsites() {
      this.$eventBus.$emit("scroll-to-component", "developWebsites");
      this.tablesle = "编程";
    },
    scrollTotoolWebsites() {
      this.$eventBus.$emit("scroll-to-component", "toolWebsites");
      this.tablesle = "工具";
    },
    scrollToinfoWebsites() {
      this.$eventBus.$emit("scroll-to-component", "infoWebsites");
      this.tablesle = "咨询";
    },
    scrollToLinkWebsites() {
      this.$eventBus.$emit("scroll-to-component", "LinkWebsites");
      this.tablesle = "友链";
    }
  }
};
</script>

<style scoped>
.nav-bar-container {
  position: absolute;
  bottom: 0;
  background: rgba(253, 251, 251, 1);
  width: 100%;
  display: flex;
  height: 9vh;
  font-weight: 900;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  transition: all 0.3s;
}

.nav-bar-item {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #333232;
  border-radius: 0px;
  flex: 1;
}

.nav-bar-container .nav-bar-item {
  flex: 1;
  width: 100%;
  height: 100%;
}

.nav-bar-container .nav-bar-item.active {
  color: #ff5500;
}

.nav-bar-container .nav-bar-item>i {
  font-size: 1.5rem;
  margin-top: 0.2rem;
}

.nav-bar-container .nav-bar-item>span {
  font-size: 1.5rem;
  font-weight: 900;
  margin-top: 0;
}

@media (max-width: 780px) {
  .nav-bar-container {
    height: 7vh;
  }

  .nav-bar-container .nav-bar-item {
    flex: 1;
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .nav-bar-container .nav-bar-item>i {
    font-size: 1.5rem !important;
  }

  .nav-bar-container .nav-bar-item>span {
    font-size: 1rem !important;
    margin-top: 0.1rem !important;
  }
}
</style>
