
<template>
  <div class="menubar">

    <el-dropdown>
      <span class="el-dropdown-link">
        邮箱
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item><a href="//mail.qq.com/" target="_blank">QQ邮箱</a></el-dropdown-item>
        <el-dropdown-item><a href="//mail.163.com/" target="_blank">163邮箱</a></el-dropdown-item>
        <el-dropdown-item><a href="//www.126.com/" target="_blank">126邮箱</a></el-dropdown-item>
        <el-dropdown-item><a href="//www.outlook.com" target="_blank">Outlook</a></el-dropdown-item>
        <el-dropdown-item><a href="//www.Gmail.com" target="_blank">G-mail</a></el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dropdown>
      <span class="el-dropdown-link">
        网盘
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item><a href="//pan.baidu.com/" target="_blank">百度网盘</a></el-dropdown-item>
        <el-dropdown-item><a href="//www.aliyundrive.com/" target="_blank">阿里云盘</a></el-dropdown-item>
        <el-dropdown-item><a href="//www.weiyun.com/" target="_blank">腾讯微云</a></el-dropdown-item>
        <el-dropdown-item><a href="//115.com/" target="_blank">115 网盘</a></el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dropdown>
      <span class="el-dropdown-link">
        翻译
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item><a href="//translate.google.cn/" target="_blank">谷歌翻译</a></el-dropdown-item>
        <el-dropdown-item><a href="//fanyi.baidu.com/" target="_blank">百度翻译</a></el-dropdown-item>
        <el-dropdown-item><a href="//fanyi.youdao.com/" target="_blank">有道翻译</a></el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dropdown>
      <span class="el-dropdown-link">
        搜图
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item><a href="//www.bing.com/visualsearch?mkt=zh-CN" target="_blank">Bing识图</a></el-dropdown-item>
        <el-dropdown-item><a href="//graph.baidu.com/pcpage/index?tpl_from=pc" target="_blank">百度识图</a></el-dropdown-item>
        <el-dropdown-item><a href="//pic.sogou.com/" target="_blank">搜狗识图</a></el-dropdown-item>
        <el-dropdown-item><a href="//st.so.com/" target="_blank">360 识图</a></el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dropdown>
      <span class="el-dropdown-link">
        音乐
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item><a href="https://music.163.com/" target="_blank">网易音乐</a></el-dropdown-item>
        <el-dropdown-item><a href="https://www.xiami.com/" target="_blank">虾米音乐</a></el-dropdown-item>
        <el-dropdown-item><a href="https://y.qq.com/" target="_blank">QQ 音乐</a></el-dropdown-item>
        <el-dropdown-item><a href="https://www.51ape.com/" target="_blank">无损音乐</a></el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>

export default {
  name: 'MenuBar',
  data() {
    return {

    };
  },
  methods: {

  },

};
</script>

<style scoped >
a{
  color: #000;
}
.menubar {
  height: 30px;
  width: 500px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.el-dropdown-link {
  cursor: pointer;
  color: #f5f6f7;
  font-size: 18px;
  font-weight: 400;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
</style>