<template>
  <div class="container">
    <div style="width: 100%; display: flex;">
      <div class="til">文字粘贴</div>

      <el-button type="success" class="loadfile" @click="saveContent" size="mini">点击保存</el-button>
    </div>
    <div class="editorcontainer">
      <vue-editor v-model="content" class="editor"></vue-editor>
    </div>

    <div style="width: 100%; display: flex;margin-top: 0px; ">
      <div class="til">文件传输</div>

      <el-button type="success" class="loadfile" @click="chooseFile" size="mini">选择文件</el-button>
    </div>

    <el-progress v-if="progress > 0" :percentage="progress"></el-progress>
    <input type="file" ref="fileInput" @change="uploadFile" />

    <div style="height: 200px; overflow: auto;">
      <el-table size="small" :data="fileList" highlight-current-row border style="width: 100%;">
        <el-table-column align="center" label="序号" width="50">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column prop="name" label="文件名" width="270"></el-table-column>
        <el-table-column align="center" prop="size" label="大小" width="90">
          <template slot-scope="scope">{{ scope.row.size }} MB</template>
        </el-table-column>
        <el-table-column align="center" label="操作" min-width="150">
          <template slot-scope="scope">
            <el-button type="primary" @click="down(scope.row.url)">下载</el-button>
            <el-button type="danger" @click="deleteFile(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { VueEditor } from "vue2-editor";

export default {
  name: "Notelist",
  components: {
    VueEditor
  },
  data() {
    return {
      fileList: [],
      progress: 0,
      content: ""
    };
  },
  created() {
    this.$eventBus.$on("notenew", this.notenew);
  },
  beforeDestroy() {
    this.$eventBus.$off("notenew", this.notenew);
  },

  mounted() {
    this.getFileList();
    this.getload();
  },
  methods: {
    notenew() {
      this.getFileList();
      this.getload();
    },
    down(url) {
      window.open(url, "_blank");
    },

    async getload() {
      const response = await this.loadtext();
      this.content = response.data.content;
    },

    async saveContent() {
      const content = this.content;
      await this.savetext(content);
    },

    chooseFile() {
      this.$refs.fileInput.click();
    },
    async uploadFile(event) {
      try {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("file", file);

        const response = await this.savefile(formData, progressEvent => {
          this.progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        });

        const result = response.data.message;
        if (result === "文件上传成功") {
          this.progress = 0;
          this.getFileList();
        } else {
          alert(result);
          this.progress = 0;
        }
      } catch (error) {
        console.error(error);
        this.progress = 0;
      }
    },
    async deleteFile(row) {
      try {
        const response = await this.deletefile(row.name);
        const result = response.data.message;
        if (result === "文件删除成功") {
          this.getFileList();
        } else {
          alert(result);
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getFileList() {
      const response = await this.getfile();

      var result = response.data;
      if (result == "没有找到文件记录") {
        this.fileList = "";
      } else {
        this.fileList = response.data;
      }
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
  width: 70%;
  margin: 0 auto;
  bottom: 1vh;
  padding: 20px;
}

.editorcontainer {
  position: relative;
  top: 0;
  overflow-y: auto;
  height: 250px;
  margin-bottom: 1px;
  width: 100%;
}

@media (max-width: 780px) {
  .container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    bottom: 0;
    padding: 0px;
  }

  .editorcontainer {
    position: relative;
    top: 0;
    overflow-y: auto;
    height: 320px;
    width: 100%;
    border: 0px solid #ccc;
  }

  .loadfile {
    color: white;
    border: none;
    margin-left: 18% !important;
    padding: 2px 8px !important;
    font-size: 12px !important;
  }

  .til {
    font-size: 18px !important;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.til {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.loadfile {
  color: rgb(15, 14, 14);
  border: none;
  margin-left: 30%;
  padding: 5px 5px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
}

input[type="file"] {
  display: none;
}

.progress-bar {
  height: 10px;
  background-color: #4caf50;
  margin-bottom: 10px;
  border-radius: 8px;
}

button {
  background-color: #20db7b;
  color: white;
  border: none;
  padding: 5px 8px;
  margin-right: 5px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 3px;
}

button:hover {
  background-color: #e53935;
}
</style>
