<template>
  <div class="footer-inner">
    <div class="footer-text">
      本站内容源自互联网，如有内容侵犯了您的权益，请联系删除相关内容。联系邮箱：
      <a href="mailto:yjl67525@163.com">yjl67525@163.com</a>&nbsp;&nbsp;
    </div>

    <div>
      &copy;2022 yjl导航&nbsp;&nbsp;备案号：
      <a href="http://beian.miit.gov.cn/" target="_blank">蜀ICP备2022025000号-1</a>
      <img src="/assets/ico/baatb.png" style="width: 15px; height: 15px;margin-top: -3px;" />
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51110202000403" target="_blank">
        川公网安备
        51110202000403号
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebinFo"
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #000;
}

.footer-inner {
  width: 100%;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

@media (max-width: 979px) {
  .footer-inner {
    display: none;
  }
}
</style>