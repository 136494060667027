// store.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        sideBarshow: false

    },
    mutations:
    {
        setSideBarShow(state, show) {
            state.sideBarshow = show;
        }

    },
    actions: {

    }
});